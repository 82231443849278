var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"add-bank-form"},[_c('h3',{staticClass:"bank-title"},[_vm._v(" Contact Info ")]),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"accountType","label":"Account Type","label-cols-md":"4"}},[_c('div',{staticClass:"flex",attrs:{"id":"accountType"}},[_c('b-form-radio',{staticClass:"mr-3 account-type",attrs:{"name":"account-type","value":"1","disabled":_vm.isAdd},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}},[_vm._v(" Individual ")]),_c('b-form-radio',{staticClass:"account-type",attrs:{"name":"account-type","value":"2","disabled":_vm.isAdd},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}},[_vm._v(" Company ")])],1)])],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"contactCountry","label":"Country","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Contact Info Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"contactCountry","options":_vm.countryListOption,"reduce":function (val) { return val.value; },"disabled":_vm.isAdd},model:{value:(_vm.contactCountry),callback:function ($$v) {_vm.contactCountry=$$v},expression:"contactCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"contactName","label":"Name","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"contactName","type":"text","placeholder":"Name","autocomplete":"off","disabled":_vm.isAdd,"formatter":function (value) { return _vm.sliceStr(value, 50); }},model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"contactAddress","label":"Address","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Contact Info Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"contactAddress","type":"text","placeholder":"Address","autocomplete":"off","disabled":_vm.isAdd,"formatter":function (value) { return _vm.sliceStr(value, 100); }},model:{value:(_vm.contactAddress),callback:function ($$v) {_vm.contactAddress=$$v},expression:"contactAddress"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"contactEmail","label":"Email","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"contactEmail","type":"text","placeholder":"Email","autocomplete":"off","disabled":_vm.isAdd,"formatter":function (value) { return _vm.sliceStr(value, 75); }},model:{value:(_vm.contactEmail),callback:function ($$v) {_vm.contactEmail=$$v},expression:"contactEmail"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.accountType === '1')?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"contactDocumentType","label":"Document Type","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Document Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"contactDocumentType","options":_vm.documentTypeOption,"reduce":function (val) { return val.value; },"disabled":_vm.isAdd},model:{value:(_vm.contactDocumentType),callback:function ($$v) {_vm.contactDocumentType=$$v},expression:"contactDocumentType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,897382290)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"contactDocumentNumber","label":"Document Number","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Document Number","rules":"required|validDocumentNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"contactDocumentNumber","type":"text","placeholder":"Document Number","autocomplete":"off","disabled":_vm.isAdd,"formatter":function (value) { return _vm.sliceStr(value, 20); }},model:{value:(_vm.contactDocumentNumber),callback:function ($$v) {_vm.contactDocumentNumber=$$v},expression:"contactDocumentNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"bank-title"},[_vm._v(" Account Info ")]),_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"accountNumber","label":"Account Number","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Account Number","rules":"required|validAccountNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"accountNumber","type":"text","placeholder":"Account Number","autocomplete":"off","disabled":_vm.isAdd,"oninput":"value=value.replace(/[^\\d\\-]/g,'');if(value.split('-').length>1){value=value.split('-')[0]+'-'+value.split('-')[1]}","formatter":function (value) { return _vm.sliceStr(value, 20); }},model:{value:(_vm.accountNumber),callback:function ($$v) {_vm.accountNumber=$$v},expression:"accountNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"currency","label":"Currency","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"currency","options":_vm.currencyOption,"reduce":function (val) { return val.value; },"disabled":_vm.isAdd},model:{value:(_vm.accountCurrency),callback:function ($$v) {_vm.accountCurrency=$$v},expression:"accountCurrency"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"accountName","label":"Account Name","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Account Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"accountName","type":"text","placeholder":"Account Name","autocomplete":"off","disabled":_vm.isAdd},model:{value:(_vm.accountName),callback:function ($$v) {_vm.accountName=$$v},expression:"accountName"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"accountCountry","label":"Country","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Account Info Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"accountCountry","options":_vm.countryListOption,"reduce":function (val) { return val.value; },"disabled":_vm.isAdd},on:{"input":function (value) { _vm.isAccountBrazil = value === 'BRA' }},model:{value:(_vm.accountCountry),callback:function ($$v) {_vm.accountCountry=$$v},expression:"accountCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"accountBankName","label":"Bank Name","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Bank Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isAccountBrazil)?_c('v-select',{attrs:{"id":"accountBankName","options":_vm.brBankNameOption,"reduce":function (val) { return val.value; },"disabled":_vm.isAdd},model:{value:(_vm.accountBankName),callback:function ($$v) {_vm.accountBankName=$$v},expression:"accountBankName"}}):_vm._e(),(!_vm.isAccountBrazil)?_c('v-select',{attrs:{"id":"accountBankName","options":_vm.mexBankNameOption,"reduce":function (val) { return val.value; },"disabled":_vm.isAdd},model:{value:(_vm.accountBankName),callback:function ($$v) {_vm.accountBankName=$$v},expression:"accountBankName"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"accountBranch","label":"Branch","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Branch","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"accountBranch","type":"text","placeholder":"Branch","autocomplete":"off","disabled":_vm.isAdd,"formatter":function (value) { return _vm.sliceStr(value, 75); }},model:{value:(_vm.accountBranch),callback:function ($$v) {_vm.accountBranch=$$v},expression:"accountBranch"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","sm":"4"}},[_c('b-form-group',{attrs:{"label-for":"accountAddress","label":"Address","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"Account Info Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"id":"accountAddress","type":"text","placeholder":"Address","autocomplete":"off","disabled":_vm.isAdd},model:{value:(_vm.accountAddress),callback:function ($$v) {_vm.accountAddress=$$v},expression:"accountAddress"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }