<template>
  <b-form class="add-bank-form">
    <h3 class="bank-title">
      Contact Info
    </h3>
    <b-row>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="accountType"
          label="Account Type"
          label-cols-md="4"
        >
          <div
            id="accountType"
            class="flex"
          >
            <!-- 1 Individual 2 Company -->
            <b-form-radio
              v-model="accountType"
              name="account-type"
              value="1"
              class="mr-3 account-type"
              :disabled="isAdd"
            >
              Individual
            </b-form-radio>
            <b-form-radio
              v-model="accountType"
              name="account-type"
              value="2"
              :disabled="isAdd"
              class="account-type"
            >
              Company
            </b-form-radio>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="contactCountry"
          label="Country"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Contact Info Country"
            rules="required"
          >
            <v-select
              id="contactCountry"
              v-model="contactCountry"
              :options="countryListOption"
              :reduce="val => val.value"
              :disabled="isAdd"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="contactName"
          label="Name"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-input-group :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="contactName"
                v-model="contactName"
                type="text"
                placeholder="Name"
                autocomplete="off"
                :disabled="isAdd"
                :formatter="value => sliceStr(value, 50)"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="contactAddress"
          label="Address"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Contact Info Address"
            rules="required"
          >
            <b-input-group :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="contactAddress"
                v-model="contactAddress"
                type="text"
                placeholder="Address"
                autocomplete="off"
                :disabled="isAdd"
                :formatter="value => sliceStr(value, 100)"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="contactEmail"
          label="Email"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-input-group :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="contactEmail"
                v-model="contactEmail"
                type="text"
                placeholder="Email"
                autocomplete="off"
                :disabled="isAdd"
                :formatter="value => sliceStr(value, 75)"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        v-if="accountType === '1'"
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="contactDocumentType"
          label="Document Type"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Document Type"
            rules="required"
          >
            <v-select
              id="contactDocumentType"
              v-model="contactDocumentType"
              :options="documentTypeOption"
              :reduce="val => val.value"
              :disabled="isAdd"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="contactDocumentNumber"
          label="Document Number"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Document Number"
            rules="required|validDocumentNumber"
          >
            <b-input-group :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="contactDocumentNumber"
                v-model="contactDocumentNumber"
                type="text"
                placeholder="Document Number"
                autocomplete="off"
                :disabled="isAdd"
                :formatter="value => sliceStr(value, 20)"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <h3 class="bank-title">
      Account Info
    </h3>
    <b-row>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="accountNumber"
          label="Account Number"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Account Number"
            rules="required|validAccountNumber"
          >
            <b-input-group :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="accountNumber"
                v-model="accountNumber"
                type="text"
                placeholder="Account Number"
                autocomplete="off"
                :disabled="isAdd"
                oninput="value=value.replace(/[^\d\-]/g,'');if(value.split('-').length>1){value=value.split('-')[0]+'-'+value.split('-')[1]}"
                :formatter="value => sliceStr(value, 20)"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="currency"
          label="Currency"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Currency"
            rules="required"
          >
            <v-select
              id="currency"
              v-model="accountCurrency"
              :options="currencyOption"
              :reduce="val => val.value"
              :disabled="isAdd"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="accountName"
          label="Account Name"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Account Name"
            rules="required"
          >
            <b-input-group :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="accountName"
                v-model="accountName"
                type="text"
                placeholder="Account Name"
                autocomplete="off"
                :disabled="isAdd"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="accountCountry"
          label="Country"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Account Info Country"
            rules="required"
          >
            <v-select
              id="accountCountry"
              v-model="accountCountry"
              :options="countryListOption"
              :reduce="val => val.value"
              :disabled="isAdd"
              @input="value => { isAccountBrazil = value === 'BRA' }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="accountBankName"
          label="Bank Name"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Bank Name"
            rules="required"
          >
            <v-select
              v-if="isAccountBrazil"
              id="accountBankName"
              v-model="accountBankName"
              :options="brBankNameOption"
              :reduce="val => val.value"
              :disabled="isAdd"
            />
            <v-select
              v-if="!isAccountBrazil"
              id="accountBankName"
              v-model="accountBankName"
              :options="mexBankNameOption"
              :reduce="val => val.value"
              :disabled="isAdd"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="accountBranch"
          label="Branch"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Branch"
            rules="required"
          >
            <b-input-group :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="accountBranch"
                v-model="accountBranch"
                type="text"
                placeholder="Branch"
                autocomplete="off"
                :disabled="isAdd"
                :formatter="value => sliceStr(value, 75)"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mt-1"
      >
        <b-form-group
          label-for="accountAddress"
          label="Address"
          label-cols-md="4"
        >
          <validation-provider
            #default="{ errors }"
            name="Account Info Address"
            rules="required"
          >
            <b-input-group :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="accountAddress"
                v-model="accountAddress"
                type="text"
                placeholder="Address"
                autocomplete="off"
                :disabled="isAdd"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormRadio,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, extend } from 'vee-validate'
import store from '@/store'
import { sliceStr } from '@/libs/utils'

extend('validDocumentNumber', {
  validate(value) {
    const reg = /[^0-9a-zA-Z]+/
    return !reg.test(value)
  },
  message: 'The {_field_} can only be letters and numbers',
})

extend('validAccountNumber', {
  validate(value) {
    return value.length >= 4
  },
  message: 'The {_field_} is not valid',
})

export default {
  name: 'AddBankComponent',
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,

    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      accountType: '1',
      contactCountry: '',
      contactName: '',
      contactAddress: '',
      contactEmail: '',
      contactDocumentType: '',
      contactDocumentNumber: '',

      accountNumber: '',
      accountCurrency: '',
      accountName: '',
      accountBankName: '',
      accountCountry: 'BRA',
      accountBranch: '',
      accountAddress: '',

      documentTypeOption: [{ label: 'ID Card', value: '7' }, { label: 'Passport', value: '8' }],
      currencyOption: [{ label: 'BRL', value: 'BRL' }, { label: 'MXN', value: 'MXN' }],
      countryListOption: [{ label: 'Brazil', value: 'BRA' }, { label: 'Mexico', value: 'MEX' }],

      isAdd: false,
      isAccountBrazil: true,
    }
  },
  computed: {
    // countryListOption() { return store.state.wallet.countryList },
    // bankNameOption() { return store.state.wallet.bankList },
    brBankNameOption() { return store.state.wallet.brazilBankList },
    mexBankNameOption() { return store.state.wallet.mexicoBankList },
  },
  beforeCreate() {
    // 获取公共数据
    if (store.state.wallet.countryList.length === 0) { store.dispatch('wallet/getCommon') }
  },
  methods: {
    getAddBackData() {
      const params = {
        contact_info: {
          account_type: Number(this.accountType),
          document_type: Number(this.contactDocumentType),
          document_number: this.contactDocumentNumber,
          email: this.contactEmail,
          address: this.contactAddress,
          name: this.contactName,
          country: this.contactCountry,
        },
        account_info: {
          account_number: this.accountNumber,
          currency: this.accountCurrency,
          account_name: this.accountName,
          bank_code: this.accountBankName,
          country: this.accountCountry,
          bank_branch: this.accountBranch,
          address: this.accountAddress,
        },
      }
      return params
    },
    resetAddBank() {
      this.accountType = '1'
      this.contactDocumentType = ''
      this.contactDocumentNumber = ''
      this.contactEmail = ''
      this.contactAddress = ''
      this.contactName = ''
      this.contactCountry = ''

      this.accountCurrency = ''
      this.accountBankName = ''
      this.accountCountry = 'BRA'
      this.accountBranch = ''
      this.accountAddress = ''
    },
    sliceStr,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.add-bank-form {
  padding: 0 20px;
  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
  .bank-title {
    color: #28c76f;
    margin: 10px 0;
  }
  .vs__clear {
    display: none;
  }
  .account-type {
    input, label {
      cursor: pointer;
    }
  }
}
</style>
