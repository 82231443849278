<template>
  <div>
    <b-card
      title="Company Info"
      class="setting-page"
    >
      <b-col cols="24 pt-0">
        <hr>
      </b-col>
      <b-row>
        <b-col
          cols="12"
          sm="4"
        >
          <b-card-text>Company Name： {{ basicInfo.company_full_name }}</b-card-text>
          <b-card-text>Email： {{ basicInfo.company_email }}</b-card-text>
          <b-card-text>Merchant Key： {{ merchantKey.merchant_key }}</b-card-text>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <b-card-text>Country： {{ countryText }} </b-card-text>
          <b-card-text>Phone Number： {{ phoneNumber }}</b-card-text>
        </b-col>
        <b-col
          cols="12"
          sm="4"
        >
          <b-card-text>Contact Name： {{ basicInfo.contact_name }}</b-card-text>
          <b-card-text>Merchant Id： {{ merchantKey.merchant_id }}</b-card-text>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-show="true"
      title="Bank Info"
      class="setting-page"
    >
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <b-col cols="24 pt-0">
          <hr>
        </b-col>
        <div class="basic-content-table">
          <b-button
            v-hasBtn="`merchant_profile`"
            class="add-bank mr-2"
            variant="success"
            @click="addBank"
          >ADD BANK</b-button>
          <div style="display: grid;">
            <b-table
              :items="list"
              responsive
              :fields="tableColumns"
              primary-key="transactionId"
              show-empty
              empty-text="No matching records found"
              class="position-relative withdraw-table"
            />
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted mr-1">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> <span class="text-muted" />
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalBank"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @change="changePagination"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-overlay>
    </b-card>
    <div class="mt-4">
      &nbsp;
    </div>
    <!-- modal-add bank -->
    <b-modal
      v-model="modalAddBank"
      :title="isAdd?'Detail':'Add Bank'"
      ok-title="Confirm"
      :hide-footer="isAdd"
      size="xl"
      :no-close-on-backdrop="true"
      @shown="resetModalAddBank"
      @close="resetModalAddBank"
      @ok="addBankOk"
    >
      <validation-observer ref="addBankRules">
        <AddBankComponent ref="addBank" />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  // BForm,
  BCard,
  BCardText,
  BRow,
  BCol,
  BButton,
  // BFormGroup,
  // BInputGroup,
  // BFormInput,
  BTable,
  // BFormRadio,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import { createNamespacedHelpers } from "vuex";
// const { mapState: mapStateMerchant } = createNamespacedHelpers("wallet");
import { initProfile, showToast } from '@/libs/utils'
import storage from '@/libs/storage'
import store from '@/store'
// import useProfile from './useProfile'
import AddBankComponent from './component/AddBankComponent.vue'

export default {
  components: {
    // BForm,
    BCard,
    BCardText,
    BRow,
    BCol,
    BButton,
    // vSelect,
    ValidationObserver,
    // BFormGroup,
    // BInputGroup,
    // BFormInput,
    BTable,
    // BFormRadio,
    BPagination,

    AddBankComponent,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalAddBank: false,
      isAdd: false,
      required,

      timezone: '',
      accountInfo: {},
      companyInfo: {},

      tableColumns: [
        { key: 'c_country', label: 'Contact info country' },
        { key: 'c_name', label: 'Name' },
        { key: 'c_address', label: 'Contact info address' },
        { key: 'c_email', label: 'Email' },
        { key: 'c_document', label: 'Document/registration #' },
        { key: 'c_number', label: 'Account Number' },
        { key: 'currency', label: 'Currency' },
        { key: 'account_name', label: 'Account Name' },
        { key: 'bank_name', label: 'Bank Name' },
        { key: 'country', label: 'Account info Country' },
        { key: 'bank_branch', label: 'Branch' },
        { key: 'address', label: 'Account info address' },
        // { key: 'operation', label: 'Operation' },
      ],
      //  ---------
      basicInfo: {},
      merchantKey: {},
      perPage: 10,
      currentPage: 1,
      totalBank: 0,
      list: [],
      showLoading: false,
    }
  },
  computed: {
    phoneNumber() {
      if (this.basicInfo.phone_number) {
        const phone = this.basicInfo.phone_number
        const preIndex = phone.length - 8
        const nextIndex = phone.length - 4
        return (
          `${phone.substring(0, preIndex)}****${phone.substring(nextIndex)}`
        )
      }
      return ''
    },
    dataMeta() {
      return {
        from: this.currentPage * this.perPage - (this.perPage - 1),
        to: this.totalBank - this.currentPage * this.perPage > 0 ? this.currentPage * this.perPage : this.totalBank,
        of: this.totalBank,
      }
    },
    countryListOption() { return store.state.wallet.countryList },
    countryText() {
      const country = this.countryListOption.filter(item => item.value === this.basicInfo.country_code)
      if (country && country.length > 0) {
        return country[0].label
      }
      return ''
    },
    brBankNameOption() { return store.state.wallet.brazilBankList },
    mexBankNameOption() { return store.state.wallet.mexicoBankList },
  },
  watch: {
    brBankNameOption() {
      this.initBankList()
    },
  },
  beforeCreate() {
    // 获取公共数据
    if (store.state.wallet.countryList.length === 0) { store.dispatch('wallet/getCommon') }
  },
  mounted() {
    const userData = storage.getItem('userData')
    const { basicInfo, merchantKey } = userData
    this.basicInfo = basicInfo
    this.merchantKey = merchantKey
    this.initBankList()
  },
  methods: {
    addBank() {
      this.modalAddBank = true
      this.isAdd = false
    },
    addBankOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      const addBankData = this.$refs.addBank.getAddBackData()
      const that = this
      that.$refs.addBankRules.validate().then(async success => {
        if (success) {
          const res = await that.$api.postBank(addBankData)
          const { code, message } = res.data
          if (code === 200) {
            that.$swal({
              text: 'Your request to add a new account has been recieved and will be processed within 48 hours.',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/account/setting.png'),
              imageWidth: 88,
              imageAlt: 'Custom image',
              showConfirmButton: true,
              allowOutsideClick: false,
              confirmButtonText: 'Okay!',
              customClass: {
                image: '',
              },
              position: 'center',
            })
            that.modalAddBank = false
            that.currentPage = 1
            // initProfile()
            that.initBankList()
          } else {
            showToast({ proxy: this, title: 'danger', message })
          }
        }
      })
    },
    resetModalAddBank() {
      this.$refs.addBank.resetAddBank()
    },
    changePagination(page) {
      this.currentPage = page
      this.initBankList()
    },
    initBankList() {
      this.showLoading = true
      // page_size: this.perPage,
      this.$api.bankList({ page_no: this.currentPage }).then(res => {
        this.showLoading = false
        const { code, data, message } = res.data
        if (code === 200) {
          const { list, pagination: { total_count } } = data
          if (list.length > 0) {
            this.list = list.map(value => {
              const documents = { 7: 'ID Card', 8: 'Passport' }
              const countries = { BRA: 'Brazil', MEX: 'Mexico' }
              // const accounts = {"1":"Individual","2":"Company"}
              const bean = {
                c_country: countries[value.contact_info.country],
                c_name: value.contact_info.name,
                c_address: value.contact_info.address,
                c_email: value.contact_info.email,
                c_document: documents[value.contact_info.document_type],
                c_number: value.account_info.account_number,
                currency: value.account_info.currency,
                account_name: value.account_info.account_name,
                bank_name: value.account_info.bank_code,
                country: countries[value.account_info.country],
                bank_branch: value.account_info.bank_branch,
                address: value.account_info.address,
              }
              if (value.account_info.country === 'BRA') {
                this.brBankNameOption.forEach(br => {
                  if (br.value === value.account_info.bank_code) {
                    bean.bank_name = br.label
                  }
                })
              } else if (value.account_info.country === 'MEX') {
                this.mexBankNameOption.forEach(mex => {
                  if (mex.value === value.account_info.bank_code) {
                    bean.bank_name = mex.label
                  }
                })
              }
              return bean
            })
          } else {
            this.list = []
          }
          this.totalBank = total_count
        } else {
          showToast({ proxy: this, title: 'danger', message })
        }
      })
    },
  },
  setup() {
    // const {
    //   // 时区
    //   // timezone,
    //   // companyInfo,
    //   // accountInfo,
    //   // countryCodeList,

    //   // refSettingListTable,
    //   // tableColumns,
    //   // list,
    //   // dataMeta,
    //   // currentPage,
    //   // totalBank,
    //   // perPage,
    //   // merchantBankList,
    //   // // add bank
    //   // contactAccountType,
    //   // contactCountryInfo,
    //   // contactName,
    //   // contactAddress,
    //   // contactEmail,
    //   // contactDocumentNumber,
    //   // contactDocumentType,
    //   // bankAccountName,
    //   // bankAccountNumber,
    //   // bankName,
    //   // bankBranch,

    //   // countryText,
    //   // // func
    //   // bindMerchantBank,
    //   // getMerchantBank,
    // } = useProfile()

    return {
      // ---use获取---
      // 时区
      // timezone,

      // companyInfo,
      // accountInfo,
      // countryCodeList,
      // formatPhoneNumber,

      // refSettingListTable,
      // tableColumns,
      // list,
      // dataMeta,
      // currentPage,
      // totalBank,
      // perPage,
      // merchantBankList,
      // // add bank
      // contactAccountType,
      // contactCountryInfo,
      // contactName,
      // contactAddress,
      // contactEmail,
      // contactDocumentNumber,
      // contactDocumentType,
      // bankAccountName,
      // bankAccountNumber,
      // bankName,
      // bankBranch,

      // countryText,
      // // func
      // bindMerchantBank,
      // getMerchantBank,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.setting-page {
  padding-bottom: 8px;
  .basic-content-table {
    position: relative;
    .add-bank {
      position: absolute;
      top: -65px;
      right: 0;
      z-index: 1;
    }
  }
  .user-key {
    padding-left: 120px;
    line-height: 38px;
  }
}
</style>
